<template>
    <div>
        <div class="full-width category-container m-t-36">
            <div class="tac container neue neueSettings">
                <Breadcrumbs :path="path" />
            </div>
        </div>
        <div class="full-width p10">
            <div class="tac container flexColumn">
                <h3 class="neue title neueSettings">altraQualità</h3>
                <p class="neue neueSettings"><b>Abbigliamento</b></p>

                <center class="only-mobi">
                  <img class="quidLogo" :src="aqLogo.url" alt="" srcset="" style="margin-bottom: 0">
                </center>
            </div>
        </div>
        <div class="full-width m-t-36 p10" style="margin-bottom:3rem;">
            <div id="infoTable" class="container neueSettings">
                <div class="container70">
                    <div class="row ">
                        <div class="col-25">
                            <p class="neueSetBold neue neueSettings">Paese</p>
                        </div>
                        <div class="col-60 col_sm-100">
                            <p class="neue neueSettings" style="font-size:15px;font-weight:lighter;">Italia</p>  
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-25">
                            <p class="neueSetBold neue neueSettings">Prodotti</p>
                        </div>
                        <div class="col-60 col_sm-100">
                            <p class="neue neueSettings" style="font-size:15px;font-weight:lighter;">altraQualità partecipa alla Cooperative Collection di On Earth con una selezione di capi di abbigliamento etico e sostenibile.</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-25">
                            <p class="neueSetBold neue neueSettings">Valore protetto / impegno</p>
                        </div>
                        <div class="col-60 col_sm-100">
                            <p class="neue neueSettings" style="font-size:15px;font-weight:lighter;">Trasparenza della filiera, valorizzazione dell’artigianato del mondo, lotta allo sfruttamento del lavoro</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-25">
                            <p class="neueSetBold neue neueSettings">Con che paesi lavora</p>
                        </div>
                        <div class="col-60 col_sm-100">
                            <p class="neue neueSettings" style="font-size:15px;font-weight:lighter;">Collabora con produttori partner in <b>Asia</b>, <b>Africa</b> e <b>America Latina</b> e anche con cooperative sociali e aziende eticamente connotate in <b>Italia</b> e in <b>Europa</b>. I capi di altraQualità hanno solitamente linee moderne ed essenziali e sono particolari perché realizzati con tecniche decorative antiche, tessiture al telaio manuale e filati naturali.</p>
                        </div>
                    </div>
                </div>
                <div class="container20">
                    <img  class="quidLogo no-mobi" :src="aqLogo.url" alt="" srcset="">
                    <img class="miniMap" :src="miniMap.url" alt="" srcset="">
                    <a class="neue neueSettings" href="https://altraq.it" target="_blank">www.altraq.it</a>
                </div>
            </div>
        </div>
        <div id="slider" class="mb-6r">
            <VueSlickCarousel v-bind="settings">
                <img src="@/assets/altraQualita/AQ-Team-2021.jpg" alt="">
                <img src="@/assets/altraQualita/assisi-L.jpg" alt="">
                <img src="@/assets/altraQualita/CH5.jpg" alt="">
                <img src="@/assets/altraQualita/CH6.jpg" alt="">
                <img src="@/assets/altraQualita/CH7.jpg" alt="">
                <img src="@/assets/altraQualita/Craftbeauty-Viet-L.jpg" alt="">
            </VueSlickCarousel>
        </div>
        <div class="full-width mb-4r p10">
            <div class="container flexColumn">
                <div class="row flexColumn">
                    <h3 class="neue title neueSettings">Descrizione</h3>
                    <p class="neue neueSettings" style="font-size:15px;font-weight:lighter;"><b>altraQualità</b> è una cooperativa di Commercio Equo e Solidale che dal 2002 importa e distribuisce in Italia prodotti artigianali e alimentari realizzati nel Sud del mondo. Tutti i prodotti sono importati secondo una <b>filiera trasparente e garantita senza sfruttamento del lavoro</b>, una scelta di sostenibilità che negli anni ha permesso alla cooperativa di crescere, dimostrando che <b>un modello economico etico può essere una concreta alternativa</b>. altraQualità lavora per aprire nuove possibilità ai produttori ed individuare strade originali, sia attraverso la creazione di prodotti che con la sperimentazione di nuovi ambiti in cui applicare il Commercio Equo.</p>
                </div>   
                <div class="row flexColumn m-t-36">
                    <h3 class="neue title neueSettings">Curiosità</h3>
                    <p class="neue neueSettings" style="font-size:15px;font-weight:lighter;">altraQualità è stata <b>la prima realtà italiana a sviluppare una linea completa di abbigliamento e accessori interamente etica e solidale</b> e a proporre t-shirt personalizzabili per il fund raising e la comunicazione aziendale, sostenibili ed etiche al 100% perché certificate bio e Fairtrade.</p>
                </div> 
            </div>
        </div>
        <ProductSlider supplier="AQ" />
    </div>
</template>
<script>
    import Breadcrumbs from '@/components/Breadcrumbs'
    import ProductSlider from '@/components/ProductSlider'
    import VueSlickCarousel from 'vue-slick-carousel'
    import 'vue-slick-carousel/dist/vue-slick-carousel.css'
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
    const aqLogo =  require('@/assets/altraQualita/altraQUALITA.png')

    export default {
        name : 'AltraQualita',
        components : {
            Breadcrumbs,
            ProductSlider,
            VueSlickCarousel
        },
        metaInfo: {
          title: 'Altraqualità'
        },
        data() {
            return {
                path: [
                    { label: 'Home', path: '/' },
                    { label: 'Brand', path: '/brand' },
                    { label: 'altraQualità', path: '/altraQualità' }
                ],
                miniMap : {
                    url : 'https://www.altromercato.it/wp-content/uploads/2021/09/Bosnia-ed-Erzegovina.jpg'
                },
                aqLogo: {
                    url: aqLogo
                },
                settings : {
                    "arrows": true,
                    "dots": false,
                    "infinite": true,
                    "slidesToShow": 4,
                    "slidesToScroll": 1,
                    "speed": 500,
                    "cssEase": "linear",
                    "variableWidth": false,
                    "responsive": [
                        {
                            "breakpoint": 668,
                            "settings": {
                                "speed": 500,
                                "slidesToShow": 2,
                                "slidesToScroll": 2,
                                "infinite": true,
                                "dots": false
                            }
                        },
                    ]
                }
            }
        }
    }

</script>
<style >
    #slider .slick-next {
        right: 67px;
    }
    #slider .slick-prev {
        left: 67px;
        z-index: 1;
    }
    .mb-4r{
        margin-bottom: 4rem;
    }
    .mb-6r{
        margin-bottom: 6rem;
    }
    .miniMap{
        max-width: 100%;
        border: 0.5px solid rgba(128, 128, 128, 0.2);
        padding: 10px;
        margin-bottom: 2rem;
    }
    .quidLogo{
        max-width: 50%;
        margin-bottom: 2rem;
    }
    .title{
        font-size: 38px;
        font-weight: 600;
        letter-spacing: 0.4px;
        margin: 0;
    }
    .container70{
        max-width: 75%;
    }
    .container20{
        max-width: 25%;
        display: flex;
        flex-direction: column;
        align-items: center;
        transform: translateY(-120px);
    }
    .neueSettings{
        letter-spacing: 0.5px;
        line-height: 1.5em;
    }
    .neueSetBold{
        font-weight: 600;
    }
    .flexColumn{
        flex-direction: column;
    }
    .col-25{
        width: 30%;
        max-width: 30%;
    }
    .col-50{
        width: 50%;
        max-width: 50%;
    }
    .col-60{
        width: 60%;
        max-width: 60%;    
    }
    .col-75{
        width: 75%;
        max-width: 75%;
    }
    @media(max-width: 668px){
        #infoTable{
            flex-direction: column;
        }
        #infoTable .container70{
            max-width: 100%;
        }
        #infoTable .container20{
            transform: unset;
            max-width: 100%;
        }
        #infoTable .quidLogo{
            max-width: 20%;
            margin-top: 2rem;
        }
        #infoTable a{
            margin-bottom: 4rem;
        }
        .col_sm-100{
            max-width: 100%;
            width: 100%;
        }
        .p10{
            padding: 10px;
        }
        .tac{
            text-align: center;
        }
    }
</style>
