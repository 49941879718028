<template>
<div class="breadcrumbs">
  <router-link v-for="(item, k) in path" class="bc neue" v-bind:key="k" :to="item.path" v-html="item.label"></router-link>
</div>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  props: ['path']
}
</script>

<style>
.breadcrumbs {
  margin-bottom: 26px;
}

.bc {
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
}

.bc:after {
  content: ' > ';
  display: inline-block;
  margin: 0 3px;
}

.bc:last-child {
  pointer-events: none;
}

.bc:last-child:after {
  content: '';
}

@media (max-width: 680px) {
  .breadcrumbs {
    padding-left: 10px;
    text-align: center;
  }
}
</style>
